import {css} from 'lit';
import {desktop, tablet} from '../../js/services/const';

export default css`
  :host {
    --padding-lg: 12px;
    --padding-sm: 8px;
    --margin-lg: 12px;
    --margin-sm: 8px;
    contain: content;
    text-align: left;
    display: block;
    position: relative;
    max-width: 14rem;
    margin-left: auto;
  }

  :host(.fullscreen) {
    position: fixed;
    top: 0;
    left: 0;
    max-width: none;
    width: 100%;
    height: 100%;
    background: white;
  }

  :host(.fullscreen) .dropdown__positioner {
    margin: var(--margin-sm);
    border-top: 1px solid #c2c2c2;
  }

  .form__wrapper.hidden {
    display: none;
  }
  .form__wrapper.fullscreen {
    padding: 0.8rem 1rem 0;
    background: white;
  }

  form {
    display: flex;
    align-items: center;
  }

  .hover__wrapper {
    border: 1px solid var(--form-element-default-border);
    border-radius: 4px;
    width: 100%;
    display: flex;
    overflow: hidden;
  }

  .hover__wrapper.border {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
  }

  .hover__wrapper:hover,
  .hover__wrapper:focus {
    border-color: var(--form-element-hover-focus-border);
  }

  .hover__wrapper.fullscreen {
    border: 0;
  }

  .register-search__wrapper {
    display: flex;
  }
  .register__button {
    flex-grow: 1;
    color: var(--button-media-default-label);
    height: 3rem;
    background: transparent;
    border-radius: 0.4rem;
    border: 0.1rem solid var(--button-media-default-label);
  }
  .register__button:active {
    background-color: var(--button-media-active-background);
    color: var(--button-media-active-label);
  }

  .single__icon {
    color: var(--header-icon-color);
    margin-top: 0.1rem;
    margin-left: 1rem;
    padding-left: 1rem;
    background: transparent;
  }

  .input__control {
    margin: 0;
    border: 0;
    border-radius: 0;
    height: 2.8rem;
    font-size: 1.6rem;
    line-height: 3rem;
    flex: 1;
    padding: 0 0.8rem;
    min-width: 4rem;
    width: 4rem;
    color: var(--form-element-default-text);
    background-color: var(--page-background);
    outline: 0;
    -webkit-appearance: none;
  }

  .input__control::placeholder {
    font-style: normal;
    color: var(--text-lowVisibility);
  }
  .input__control:focus::placeholder {
    color: var(--text-lowVisibility);
  }

  .input__control.fullscreen {
    border: 0;
    border-radius: 0;
  }

  .input__control::-webkit-search-decoration,
  .input__control::-webkit-search-cancel-button,
  .input__control::-webkit-search-results-button,
  .input__control::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .input__submit.fullscreen {
    display: none;
  }

  button {
    background: white;
    border: 0;
    width: 3.2rem;
    height: 2.8rem;
    text-align: center;
    margin: 0;
    position: relative;
  }

  button span {
    width: 3.4rem;
    height: 2.8rem;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 50%;
    position: absolute;
    top: 0;
    left: -2px;
    color: var(--form-icon-default);
  }

  button span:hover {
    cursor: pointer;
  }

  .back-icon {
    background-image: var(--bg-search-back);
  }

  .clear-icon {
    background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iRWJlbmVfNCIgZGF0YS1uYW1lPSJFYmVuZSA0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAzMCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiM3MTcxNzE7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTIyLjg0LDguMjRsLS45My0uOTNhLjU0LjU0LDAsMCwwLS43NiwwTDE1LDEzLjQ1LDguODUsNy4yOWEuNTQuNTQsMCwwLDAtLjc2LDBsLS45My45M2EuNTQuNTQsMCwwLDAsMCwuNzZsNi4xNiw2LjE2TDcuNDQsMjFhLjU0LjU0LDAsMCwwLDAsLjc2bC45My45M2EuNTQuNTQsMCwwLDAsLjc2LDBMMTUsMTYuODNsNS44OCw1Ljg4YS41NC41NCwwLDAsMCwuNzYsMGwuOTQtLjkzYS41NC41NCwwLDAsMCwwLS43NkwxNi43LDE1LjE0LDIyLjg0LDlBLjU0LjU0LDAsMCwwLDIyLjg0LDguMjRaIi8+PC9zdmc+');
  }

  .submit-icon {
    background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iRWJlbmVfNCIgZGF0YS1uYW1lPSJFYmVuZSA0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAzMCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiM3MTcxNzE7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTI0Ljg1LDIzbC00LjUyLTQuNTJhOC41Miw4LjUyLDAsMSwwLTEuNzYsMS44bDQuNSw0LjVhLjUxLjUxLDAsMCwwLC43MSwwbDEuMDctMS4wN0EuNS41LDAsMCwwLDI0Ljg1LDIzWk0xMy40NiwyMEE2LjQ4LDYuNDgsMCwxLDEsMjAsMTMuNTQsNi40OCw2LjQ4LDAsMCwxLDEzLjQ2LDIwWiIvPjwvc3ZnPg==');
  }

  .dropdown__positioner {
    position: relative;
    background: white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .menu {
    list-style: none;
    display: none;
    background: white;
    padding: 0;
    margin: 0;
  }

  .menu li.menu-item a {
    display: flex;
    align-items: center;
    height: 40px;
  }

  .menu li.menu-item a.has__category {
    height: 60px;
  }

  .menu li.menu-item a span {
    display: inline-block;
  }

  .menu li.menu-item a span.image {
    display: flex;
    justify-content: center;
    margin-left: var(--margin-sm);
    margin-right: var(--margin-sm);
    min-width: 40px;
  }

  .menu li.menu-item a span.image img {
    border: 4px;
  }

  .menu li.menu-item a span.result {
    flex: 1;
    align-content: flex-end;
    line-height: 1.8rem;
  }

  .menu li.menu-item a span.suggest {
    color: var(--cp-grey-light-1);
    font-size: 1.6rem;
    font-weight: 700;
  }

  .menu li.menu-item a span.label {
    color: var(--cp-grey-light-4);
    font-size: 1.4rem;
    width: 100%;
  }

  .menu li:not(.menu-item) {
    padding-right: var(--padding-sm);
    height: 40px;
    display: flex;
    justify-content: end;
    align-items: center;
    color: var(--cp-grey-light-4);
    font-size: 10pt;
    border-top: 1px solid #c2c2c2;
    visibility: hidden;
  }

  .show {
    display: block;
  }

  .highlight {
    background: var(--highlight-search-color);
  }

  .suggest-temperature {
    color: #515151;
    font-size: 30px;
    line-height: 40px;
    margin-right: 12px;
  }

  @media (min-width: ${tablet}px) {
    :host {
      width: 47rem;
      max-width: none;
    }

    .dropdown__positioner {
      border-top: 0;
      padding: 0;
      margin: 0;
    }

    .menu {
      border: 1px solid #c2c2c2;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .menu li.menu-item a span.image {
      margin-left: var(--margin-lg);
      margin-right: var(--margin-lg);
    }

    .menu li:not(.menu-item) {
      visibility: initial;
      padding-right: var(--padding-lg);
    }
  }

  @media (min-width: ${desktop}px) {
    :host {
      width: 63rem;
      max-width: none;
    }
  }
`;
